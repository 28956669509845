import React, { useEffect, useCallback, useState, createContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NextPage } from "next";
import { useRouter } from "next/router";
import { useContextualRouting } from "next-use-contextual-routing";
import dynamic from "next/dynamic";
import {
    isEmpty,
    ACTION_TYPES,
    API_ACTION_TYPES,
    createAction,
    FETCH_STATUS,
    useFetchStatus,
    useCanRender,
    useModal,
    MODAL_TYPES,
    STORAGE_KEYS,
    SELECTORS,
    useData,
    useFetchNavbarItemData,
    TEMPLATE_KEYS,
    ITemplateKey,
    DEFAULT_TEMPLATE_KEY,
} from "@Utils";
import {
    getSessionStorageItem,
    setSessionStorageItem,
} from "repoV2/utils/common/storage/getterAndSetters";
import { IHostPage, IHost, IUtils } from "@Interfaces";
import { ITemplate } from "@Templates/ITemplate";
import { IGetInTouch } from "@Modules/modals/components/GetInTouch/IGetInTouch";
import {
    ElementaryRoot,
    ClassicRoot,
    ModernRoot,
    UnfoldRoot,
    LinktempRoot,
    YogaRoot,
    EduverseRoot,
    GrowthRoot,
    Unlock,
} from "@Templates";
import { useAccessRestriction } from "@Modules/eventPage/utils/useAccessRestriction";
import {
    BlogVisitorPopup,
    SharePopup,
    LoadingIndicator,
} from "@Modules/common";
import { LOADING_INDICATOR_DESIGNS } from "repoV2/components/common/LoadingIndicators/LoadingIndicator.data";
import { useSetCurrentPlan } from "@Modules/eventPage/utils/useSetCurrentPlan";
import { triggerPageViewAnalyticsEvent } from "repoV2/features/Common/Analytics/Analytics.utils";
import { getHostPageProps } from "../../utils/pageProps";
import styles from "./host.module.scss";

const Placeholder = dynamic(() => import("@Modules/hostPage/Placeholder"));
const HTMLHeader = dynamic(() => import("@Modules/common/HTMLHeader"));

const TEMPLATE_ROOT_COMPONENTS = {
    [TEMPLATE_KEYS.CLASSIC]: ClassicRoot,
    [TEMPLATE_KEYS.ELEMENTARY]: ElementaryRoot,
    [TEMPLATE_KEYS.MODERN]: ModernRoot,
    [TEMPLATE_KEYS.UNFOLD]: UnfoldRoot,
    [TEMPLATE_KEYS.LINKTEMP]: LinktempRoot,
    [TEMPLATE_KEYS.YOGA]: YogaRoot,
    [TEMPLATE_KEYS.EDUVERSE]: EduverseRoot,
    [TEMPLATE_KEYS.GROWTH]: GrowthRoot,
    [TEMPLATE_KEYS.UNLOCK]: Unlock,
};

export const MiscellaneousDataContext = createContext(null);

const Host: NextPage<IHostPage.IProps, IHostPage.InitialProps> = ({
    template,
    preview,
    sampleTemplate,
    initContact, // Whether to open the get in touch form on loading
    hostName = window.location.hostname.split(".")[0],
    isSiteBuilder,
    isContactFormPreview,
    isExitIntentFormPreview,
}) => {
    const host: IHost.IStore = useSelector(SELECTORS.host);
    const router = useRouter();
    const { makeContextualHref, returnHref } = useContextualRouting();

    const dispatch = useDispatch();

    const { openModal, closeModal, modalInfo } = useModal();
    const { isModalOpen, modalType } = modalInfo;

    const { currentPlanUuid, setCurrentPlanId } = useSetCurrentPlan();

    const [pauseTestimonialsAutoplay, setPauseTestimonialsAutoplay] =
        useState(false);

    const [pauseGalleryAutoplay, setPauseGalleryAutoplay] = useState(false);

    const [miscellaneousData, setMiscellaneousData] = useState(null);

    /**
     * `templateKey = query param || api response || default template`
     */
    const templateKey: ITemplateKey =
        (template as ITemplateKey) || host.template || DEFAULT_TEMPLATE_KEY;

    useEffect(() => {
        const listenerFn = (event: MessageEvent<any>) => {
            // NEXT_PUBLIC_PREVIEW_URL can be a single URL or a comma separated list
            const validSources = `${process.env.NEXT_PUBLIC_PREVIEW_URL || ""}`
                .split(",")
                .filter(Boolean)
                .map(p => p.trim());

            if (
                !(
                    (
                        event?.data &&
                        typeof event?.data === "object" &&
                        (validSources || []).includes(event.origin)
                    ) // To ensure no unwanted frame can access the preview mode
                )
            )
                return;

            try {
                if (event?.data?.type === "miscellaneous_data") {
                    setMiscellaneousData(event?.data?.miscellaneous_data);
                } else {
                    dispatch(
                        createAction(
                            ACTION_TYPES.HOST.UPDATE_SITE_BUILDER_DATA,
                            event?.data
                        )
                    );
                }
            } catch (error) {
                console.log("Iframe message error: ", error, event);
            }
        };

        if (isSiteBuilder) {
            window.addEventListener("message", listenerFn);
            return () => {
                window.removeEventListener("message", listenerFn);
            };
        }
        return () => {};
    }, []);

    useEffect(() => {
        triggerPageViewAnalyticsEvent({
            hostName,
        });

        dispatch(
            createAction(ACTION_TYPES.UTILS.POST_ANALYTICS, {
                screen: "mypageScreen",
                funnel: "mypage",
                flow: "mypage",
                event_name: "completelyLoaded",
                action: "view",
            })
        );
    }, []);

    const postAnalyticalEvent = useCallback((eventDetails: Object) => {
        dispatch(
            createAction(ACTION_TYPES.UTILS.POST_ANALYTICS, {
                ...eventDetails,
                screen: "mypageScreen",
                funnel: "mypage",
                flow: "mypage",
            })
        );
    }, []);

    const onHideModal = (args: IUtils.IModal): void => {
        if (args.modalType === MODAL_TYPES.EventModal) {
            router.push("", returnHref, { shallow: true });
            dispatch(
                createAction(ACTION_TYPES.UTILS.UPDATE_API_CALL_STATUS, {
                    [API_ACTION_TYPES.FETCH_EVENT]: FETCH_STATUS.NOT_FETCHED,
                })
            );
            // Clear out the booking data for the event opened
            dispatch(createAction(ACTION_TYPES.EVENT.RESET_BOOKING_DATA));
            dispatch(createAction(ACTION_TYPES.EVENT.RESET_DISCOUNT));
        }
        if (args.modalType === MODAL_TYPES.ExitIntent) {
            const count: number =
                getSessionStorageItem(STORAGE_KEYS.EXIT_INTENT_COUNT) ?? 0;
            setSessionStorageItem(
                STORAGE_KEYS.EXIT_INTENT_COUNT,
                count ? count + 1 : 1
            );
        }
    };

    useEffect(() => {
        // @dev currentPlanId is unset here in case plan event
        // was opened in EventModal and then closed
        if (!isModalOpen && currentPlanUuid) setCurrentPlanId(null);
        return () => {
            if (isModalOpen) {
                onHideModal(modalInfo);
            }
        };
    }, [isModalOpen]);

    // TODO: Move to a common context
    const handlePreviewPopup = useCallback((): void => {
        openModal(MODAL_TYPES.PreviewPopup, {
            modalProps: {
                sampleTemplate,
                onClickBack: closeModal,
                onClickContinue: closeModal,
            },
        });
    }, []);

    const onGetInTouchClick = useCallback(
        (disableAnalyticsCall?: boolean, listingId?: number): void => {
            if (preview) {
                handlePreviewPopup();
            } else {
                if (!disableAnalyticsCall) {
                    postAnalyticalEvent({
                        event_name: "getInTouch",
                        action: "Tap",
                        ui_component: "Button",
                        identifier: "Get in touch",
                    });
                }

                openModal(MODAL_TYPES.GetInTouch, {
                    modalProps: {
                        disableClose: isContactFormPreview,
                        template: templateKey,
                        listingId,
                    } as IGetInTouch.IBaseProps,
                });
            }
        },
        []
    );

    const handleSocialShare: ITemplate.IOnShareClick = useCallback(
        (source, data) => {
            if (preview) {
                handlePreviewPopup();
            } else {
                openModal(MODAL_TYPES.SocialShare, {
                    modalProps: {
                        postAnalyticalEvent,
                        source: source || "spread",
                        data: data || {},
                    },
                });
            }
        },
        []
    );

    const TemplateComponent = Object.values(TEMPLATE_KEYS).includes(templateKey)
        ? TEMPLATE_ROOT_COMPONENTS[templateKey]
        : TEMPLATE_ROOT_COMPONENTS[DEFAULT_TEMPLATE_KEY];
    const {
        hostData,
        headerProps,
        navbarProps,
        aboutSectionProps,
        eventSectionsProps,
        testimonialsSectionProps,
        gallerySectionProps,
        teamSectionProps,
        blogPostsSectionProps,
        footerSectionProps,
        topFeaturesProps,
        statsSectionProps,
    } = getHostPageProps({
        preview,
        host,
        hostName,
        handlePreviewPopup,
        postAnalyticalEvent,
        router,
        makeContextualHref,
        onGetInTouchClick,
        openModal,
        pauseTestimonialsAutoplay,
        setPauseTestimonialsAutoplay,
        template: templateKey,
        onShareClick: handleSocialShare,
        pauseGalleryAutoplay,
        setPauseGalleryAutoplay,
        dispatch,
    });

    const { offeringAccessDenied } = useAccessRestriction({
        isPageFilterEnabled: hostData?.is_page_filter_enabled,
    });

    useEffect(() => {
        if (offeringAccessDenied) {
            const redirectUrl = window.location.origin;
            window.location.href = redirectUrl;
        }
    }, [offeringAccessDenied]);

    const showExitIntent = () => {
        if (preview) return;
        const showIntent = getSessionStorageItem(STORAGE_KEYS.BOOKED_DEMO);
        const count: number =
            getSessionStorageItem(STORAGE_KEYS.EXIT_INTENT_COUNT) ?? 0;
        if (
            !showIntent &&
            !isEmpty(hostData) &&
            !isEmpty(hostData?.exit_intent) &&
            count < 2
        ) {
            openModal(MODAL_TYPES.ExitIntent, {
                modalProps: {
                    onClickBack: closeModal,
                    hostName,
                    hostData,
                    disableClose: isExitIntentFormPreview,
                },
            });
        }
    };

    const { data: addToCartLoading, setData: setAddToCartLoading } = useData(
        "addToCartLoading",
        false
    );

    // Open event modal as popup
    useEffect(() => {
        if (router?.query?.eventId) {
            openModal(MODAL_TYPES.EventModal, {
                modalProps: {
                    eventId: router.query.eventId as string,
                    hostName,
                    urlQueryParams: router?.query,
                    addToCartCallback: () => {
                        setAddToCartLoading(true);
                        setTimeout(() => {
                            setAddToCartLoading(false);
                        }, 7000);
                    },
                },
            });
        }
        // intentionally kept in hook(instead of `openModal` call) to avoid bugs
    }, [router.query]);

    useEffect(() => {
        // No need for exit intent if isSiteBuilder === true
        if (isSiteBuilder) return () => {};

        // Using a callback that rebinds the dependencies since the callbacks
        // in the useEffect cannot read the updated values that aren't present
        // in the dependency array, but we cannot have rebinding of callbacks
        // without removing the previous version of bindings
        // https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback

        const showPopup = () => {
            if (!isModalOpen) showExitIntent();
        };

        // show pop up when user mouse leaves the view after 30 seconds
        const onMouseLeave = () =>
            document.addEventListener("mouseleave", showPopup);
        const timer1 = setTimeout(onMouseLeave, 30_000);
        // we cant track mouse leave in touch screens
        // so if mouseleave event does not happen till 45 sec show the popup
        const timer2 = setTimeout(showPopup, 45_000);

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
            document.removeEventListener("mouseleave", showPopup);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalOpen]);

    useEffect(() => {
        if (isExitIntentFormPreview) {
            showExitIntent();
        }
    }, [isExitIntentFormPreview]);

    useEffect(() => {
        // No need for this if isSiteBuilder = true
        if (isSiteBuilder) return;

        // If a browser back navigation is performed on the modal,
        // this callback will trigger and close the modal
        router.beforePopState(({ url, as: asProp, options }) => {
            if (
                isModalOpen &&
                modalType === MODAL_TYPES.EventModal &&
                url.startsWith("/host") &&
                options?.shallow &&
                asProp
            ) {
                // Back to host page from modal
                closeModal();
            } else if (
                !isModalOpen &&
                url.startsWith("/host?eventId=") &&
                options?.shallow
            ) {
                // Open event modal
                const uuid = asProp.split("/")[1];
                router.push(
                    makeContextualHref({ eventId: uuid }),
                    `${uuid}/${window.location.search}`,
                    { shallow: true }
                );
            } else if (!isModalOpen && url.startsWith("/event?eventId=")) {
                // Nav to event page
                router.push(asProp);
            }
            return true;
        });
        // TODO: test on removing dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router]);

    const isFetched =
        useFetchStatus(API_ACTION_TYPES.FETCH_HOST) === FETCH_STATUS.FETCHED;
    const canRender = useCanRender();

    // For handling the init_contact query param
    // Storing the state of whether it has been shown since
    // the modal opening re-renders the whole tree and the
    // useEffect tuns again even without any dependencies
    // Will need to prevent this unnecessary render in the future.
    const { data: hasShownInitContact, setData: setHasShownInitContact } =
        useData<boolean>("hasShownInitContact");

    useEffect(() => {
        if (initContact && !hasShownInitContact) {
            onGetInTouchClick();
            setHasShownInitContact(true);
        }
    }, [initContact, hasShownInitContact]);

    useFetchNavbarItemData(hostName);

    const { data: showBlogVisitorPopup, setData: setShowBlogVisitorPopup } =
        useData("showBlogVisitorPopup");
    const closeBlogVisitorPopup = () => {
        setShowBlogVisitorPopup(false);
    };

    const { data: sharePopupData, setData: setSharePopupData } =
        useData("sharePopupData");
    const closeSharePopup = () => {
        setSharePopupData({ show: false, props: {} });
    };

    const templateProps: ITemplate.IProps = {
        hostName,
        hostData: hostData!,
        preview,
        handlePreviewPopup,
        postAnalyticalEvent,
        handleSocialShare,

        // props to be used by sub-components across all Templates
        navbarProps,
        aboutSectionProps,
        eventSectionsProps,
        testimonialsSectionProps,
        gallerySectionProps,
        teamSectionProps,
        blogPostsSectionProps,
        footerSectionProps,
        topFeaturesProps,
        statsSectionProps,
    };

    /**
     * duplication of rendered components causes apis to be hit twice
     *
     * reason for duplication of rendered components:
     * 1. needed for SEO
     * 2. using same render that was shown in server side was causing issues
     *
     * some of the known issues when same render is used
     * 1. host pages not opening for US customers - https://exly.slack.com/archives/C033Y6CMBCL/p1674024184542549
     * 2. users not geting all items in class selection dropdown in mobile - https://exly.slack.com/archives/C033Y6CMBCL/p1674015040307449
     * 3. plan link is not working properly on Android it is only showing one listing - https://exly.slack.com/archives/C033Y6CMBCL/p1673969501065769
     * 4. ExlyImage loader not getting removed - https://exly.slack.com/archives/C033Y6CMBCL/p1673956053252549
     *
     * to understand why these issues happen, read this - https://www.joshwcomeau.com/react/the-perils-of-rehydration/
     */
    if (!canRender && addToCartLoading)
        return (
            <LoadingIndicator
                design={LOADING_INDICATOR_DESIGNS.rotating_arcs}
            />
        );

    if (!isFetched)
        return (
            <div className={styles.overlay}>
                <Placeholder />
            </div>
        );

    return (
        <>
            <HTMLHeader {...headerProps} />
            <MiscellaneousDataContext.Provider value={miscellaneousData}>
                <TemplateComponent {...templateProps} />
            </MiscellaneousDataContext.Provider>
            {showBlogVisitorPopup ? (
                <BlogVisitorPopup
                    classNames={{ root: styles.popupRoot }}
                    closePopup={closeBlogVisitorPopup}
                />
            ) : null}
            {sharePopupData?.show ? (
                <SharePopup
                    classNames={{ root: styles.popupRoot }}
                    closePopup={closeSharePopup}
                    {...sharePopupData.props}
                />
            ) : null}
        </>
    );
};

Host.getInitialProps = async (
    ctx: IHostPage.IPageContext
): Promise<IHostPage.InitialProps> => {
    const {
        template,
        theme,
        font,
        preview,
        sampleTemplate,
        init_contact: initContact,
        is_builder: isSiteBuilder,
        icf_preview: isContactFormPreview,
        ei_preview: isExitIntentFormPreview,
    }: IHostPage.IRouterQuery = ctx.query;

    const { hostName } = ctx.params;

    // These dispatches are not async thunks but synchronous redux
    //  actions, so no need to launch them all in parallel
    ctx.store.dispatch(
        createAction(ACTION_TYPES.UTILS.API_CALL, {
            apiActionType: API_ACTION_TYPES.FETCH_HOST,
            urlArgs: { hostName, utm_affiliate: ctx.query.utm_affiliate },
        })
    );

    ctx.store.dispatch(
        theme || template || font
            ? createAction(ACTION_TYPES.UTILS.API_CALL, {
                  apiActionType: API_ACTION_TYPES.FETCH_UI_CUSTOMIZATION,
                  urlArgs: {
                      themeName: theme,
                      templateName: template,
                      fontName: font,
                  },
              })
            : createAction(ACTION_TYPES.UTILS.API_CALL, {
                  apiActionType: API_ACTION_TYPES.FETCH_HOST_THEME,
                  urlArgs: { hostName },
              })
    );

    return {
        template,
        theme,
        preview: !!preview,
        hostName,
        sampleTemplate,
        initContact,
        isSiteBuilder: !!isSiteBuilder,
        isContactFormPreview,
        isExitIntentFormPreview,
    };
};

export default Host;
