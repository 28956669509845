import dynamic from "next/dynamic";

export const ElementaryRoot = dynamic(
    () => import("@Templates/elementary/root")
);
export const ClassicRoot = dynamic(() => import("@Templates/classic/root"));
export const ModernRoot = dynamic(() => import("@Templates/modern/root"));
export const UnfoldRoot = dynamic(() => import("@Templates/unfold/root"));
export const LinktempRoot = dynamic(() => import("@Templates/linktemp/root"));
export const YogaRoot = dynamic(() => import("@Templates/yoga/root"));
export const GrowthRoot = dynamic(() => import("@Templates/growth/root"));
export const EduverseRoot = dynamic(() => import("@Templates/eduverse/root"));
export const Unlock = dynamic(() => import("@Templates/unlock/root"));
